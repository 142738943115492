import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import TokenInfo from 'components/TokenInfo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "color",
      "style": {
        "position": "relative"
      }
    }}>{`Color`}</h2>
    <TokenInfo prefix="var(--" suffix=")" platform="css" scope="color" mdxType="TokenInfo" />
    <h2 {...{
      "id": "space",
      "style": {
        "position": "relative"
      }
    }}>{`Space`}</h2>
    <TokenInfo prefix="var(--" suffix=")" platform="css" scope="space" mdxType="TokenInfo" />
    <h2 {...{
      "id": "z-index",
      "style": {
        "position": "relative"
      }
    }}>{`Z-index`}</h2>
    <TokenInfo prefix="var(--" suffix=")" platform="css" scope="zIndex" mdxType="TokenInfo" />
    <h2 {...{
      "id": "shadows",
      "style": {
        "position": "relative"
      }
    }}>{`Shadows`}</h2>
    <TokenInfo prefix="var(--" suffix=")" platform="css" scope="shadow" mdxType="TokenInfo" />
    <h2 {...{
      "id": "grid",
      "style": {
        "position": "relative"
      }
    }}>{`Grid`}</h2>
    <TokenInfo prefix="var(--" suffix=")" platform="css" scope="grid" mdxType="TokenInfo" />
    <h2 {...{
      "id": "typography",
      "style": {
        "position": "relative"
      }
    }}>{`Typography`}</h2>
    <h3 {...{
      "id": "font-family",
      "style": {
        "position": "relative"
      }
    }}>{`Font-family`}</h3>
    <TokenInfo prefix="var(--" suffix=")" platform="css" scope="fontFamily" mdxType="TokenInfo" />
    <h3 {...{
      "id": "font-weight",
      "style": {
        "position": "relative"
      }
    }}>{`Font-weight`}</h3>
    <TokenInfo prefix="var(--" suffix=")" platform="css" scope="fontWeight" mdxType="TokenInfo" />
    <h3 {...{
      "id": "line-height",
      "style": {
        "position": "relative"
      }
    }}>{`Line-height`}</h3>
    <TokenInfo prefix="var(--" suffix=")" platform="css" scope="lineHeight" mdxType="TokenInfo" />
    <h3 {...{
      "id": "font-size-type-scale",
      "style": {
        "position": "relative"
      }
    }}>{`Font-size (type scale)`}</h3>
    <TokenInfo prefix="var(--" suffix=")" platform="css" scope="fontSize" mdxType="TokenInfo" />
    <h3 {...{
      "id": "font-system",
      "style": {
        "position": "relative"
      }
    }}>{`Font System`}</h3>
    <TokenInfo prefix="var(--" suffix=")" platform="css" scope="font" mdxType="TokenInfo" />
    <h2 {...{
      "id": "border-radius",
      "style": {
        "position": "relative"
      }
    }}>{`Border Radius`}</h2>
    <TokenInfo prefix="var(--" suffix=")" platform="css" scope="borderRadius" mdxType="TokenInfo" />
    <h2 {...{
      "id": "overlays",
      "style": {
        "position": "relative"
      }
    }}>{`Overlays`}</h2>
    <TokenInfo prefix="var(--" suffix=")" platform="css" scope="overlay" mdxType="TokenInfo" />
    <h2 {...{
      "id": "motion",
      "style": {
        "position": "relative"
      }
    }}>{`Motion`}</h2>
    <TokenInfo prefix="var(--" suffix=")" platform="css" scope="motion" mdxType="TokenInfo" />
    <h2 {...{
      "id": "icon",
      "style": {
        "position": "relative"
      }
    }}>{`Icon`}</h2>
    <TokenInfo prefix="var(--" suffix=")" platform="css" scope="icon" mdxType="TokenInfo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      